//

import { Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretLeft,
    faCaretRight,
    faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch } from "../app/hooks";
import { setVideo } from "../app/slices/video";
import { showPlayer } from "../app/slices/playerVisibility";
import { Video } from "../app/types";
import { useState } from "react";

function VideosTable({ videos }: { videos: Video[] }) {
    //

    const pageSize = 10;

    const [page, setPage] = useState(0);

    const aIdx = page * pageSize;
    const bIdx = Math.min(aIdx + pageSize, videos.length);

    const videos_ = videos.slice(aIdx, bIdx);

    return (
        <div className="m2m-table mx-auto mt-3">
            <div className="m2m-table-scroll">
                <Table striped hover>
                    <tbody>
                        {videos_.map((e, i) => {
                            return <TRow idx={aIdx + i + 1} video={e} />;
                        })}
                    </tbody>
                </Table>
            </div>
            <Navigation
                page={page}
                idxA={aIdx}
                idxB={bIdx}
                videos={videos.length}
                pageSize={pageSize}
                setPage={setPage}
            />
        </div>
    );
}

function TRow({ idx, video }: { idx: number; video: Video }) {
    //
    const dispatch = useAppDispatch();

    return (
        <tr key={idx}>
            {/* id */}
            <td style={{ fontSize: "3vmin" }}>{idx}</td>

            {/* thumbnail */}
            <td>
                <div style={{ position: "relative" }}>
                    <img src={video.thumb} alt={video.title} />
                    <Button
                        className="m2m-play-button"
                        type="button"
                        variant="link"
                        onClick={() => {
                            dispatch(setVideo(video));
                            dispatch(showPlayer());
                        }}
                    >
                        <FontAwesomeIcon icon={faPlay} />
                    </Button>
                </div>
            </td>

            {/* title */}
            <td>
                <a
                    className="m2m-link-2"
                    href={video.url}
                    target="_blank"
                    rel="noreferrer"
                    style={{ fontSize: "2vmin" }}
                >
                    {video.title}
                </a>
            </td>
        </tr>
    );
}

function Navigation({
    page,
    idxA,
    idxB,
    videos,
    pageSize,
    setPage,
}: {
    page: number;
    idxA: number;
    idxB: number;
    videos: number;
    pageSize: number;
    setPage: (n: number) => void;
}) {
    return (
        <div className="my-2">
            <Button
                type="button"
                className="m2m-button"
                disabled={page === 0}
                onClick={() => setPage(page - 1)}
            >
                <FontAwesomeIcon icon={faCaretLeft} size="xl" />
            </Button>

            <span id="m2m-pagination" className="mx-3">
                {idxA + 1} - {idxB} de {videos}
            </span>

            <Button
                type="button"
                className="m2m-button"
                disabled={page === Math.ceil(videos / pageSize) - 1}
                onClick={() => setPage(page + 1)}
            >
                <FontAwesomeIcon icon={faCaretRight} size="xl" />
            </Button>
        </div>
    );
}

export default VideosTable;
