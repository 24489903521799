//

import { Button, Form, Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setSearchKeywords } from "../app/slices/searchKeywords";
import { triggerSearch } from "../app/slices/searchTrigger";
import { useNavigate } from "react-router-dom";

function MenuSidebar({
    visible,
    hide,
}: {
    visible: boolean;
    hide: () => void;
}) {
    //

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const sections = useAppSelector((state) => state.sections.value);
    const keywords = useAppSelector((state) => state.searchKeywords.value);

    return (
        <Offcanvas
            id="m2m-menu-sidebar"
            show={visible}
            onHide={hide}
            aria-labelledby="m2m-menu-sidebar-title"
            placement="start"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title id="m2m-menu-sidebar-title">
                    Secciones
                </Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <Form className="d-flex mb-3">
                    <Form.Control
                        type="search"
                        placeholder="Buscar"
                        className="me-2"
                        aria-label="Search"
                        value={keywords}
                        onChange={(e) =>
                            dispatch(setSearchKeywords(e.target.value))
                        }
                    />
                    <Button
                        className="m2m-button"
                        type="button"
                        onClick={() => {
                            navigate("/search");
                            dispatch(triggerSearch());
                            hide();
                        }}
                        disabled={keywords === ""}
                    >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Button>
                </Form>

                {sections.map((e, i) => {
                    return (
                        <Button
                            key={i}
                            type="button"
                            className="section-button my-1"
                            style={{
                                backgroundColor: e.color,
                                color: e.textColor,
                                borderColor: e.textColor,
                            }}
                            href={`/${e.slug}`}
                        >
                            {e.title}
                        </Button>
                    );
                })}
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default MenuSidebar;
