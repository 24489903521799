//

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Video } from "../types";

interface VideoState {
    value: Video;
}

export const initialState: VideoState = {
    value: {
        title: "Rick Astley - Never Gonna Give You Up",
        url: "https://youtu.be/dQw4w9WgXcQ",
        thumb: "https://i.ytimg.com/vi/dQw4w9WgXcQ/maxresdefault.jpg",
    },
};

export const videoSlice = createSlice({
    name: "video",
    initialState,
    reducers: {
        setVideo: (state, action: PayloadAction<Video>) => {
            state.value = action.payload;
        },
    },
});

export const { setVideo } = videoSlice.actions;

export default videoSlice.reducer;
