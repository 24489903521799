//

import { createSlice } from "@reduxjs/toolkit";

interface PlayerVisibilityState {
    value: boolean;
}

export const initialState: PlayerVisibilityState = {
    value: false,
};

export const playerVisibilitySlice = createSlice({
    name: "playerVisibility",
    initialState,
    reducers: {
        showPlayer: (state) => {
            state.value = true;
        },
        hidePlayer: (state) => {
            state.value = false;
        },
    },
});

export const { showPlayer, hidePlayer } = playerVisibilitySlice.actions;

export default playerVisibilitySlice.reducer;
