//

import logo from "../m2m_logo_purple.png";

function M2MHeader() {
    return (
        <div id="m2m-header">
            <img id="m2m-logo" src={logo} alt="math2me logo" className="my-2" />
        </div>
    );
}

export default M2MHeader;
