import { useEffect, useState } from "react";

import "./App.css";
import M2MHeader from "./features/M2MHeader";
import TitleBar from "./features/TitleBar";
import VideosTable from "./features/VideosTable";
import ModalVideoPlayer from "./features/ModalVideoPlayer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { setSections } from "./app/slices/sections";
import PlaylistTable from "./features/PlaylistTable";
import { setTitleBarData } from "./app/slices/titleBarData";
import { initialState as defaultVideo } from "./app/slices/video";
import { Playlist, Section } from "./app/types";

// enum SectionName {
//     Latest = 0,
//     Arithmetic,
//     Geometry,
//     Trigonometry,
//     Algebra,
//     AnalyticGeometry,
//     Statistics,
//     Probability,
//     Precalculus,
//     DifferentialCalculus,
//     IntegralCalculus,
//     LinearAlgebra,
//     DifferentialEquations,
//     Physics,
//     MiddleSchool,
//     HighSchool,
// }

// export const HOST = "http://localhost:3001";
// export const HOST = "https://playlistsv2.math2me.app";
export const HOST = "https://cursos.math2me.com";

// const GET_DATA = `${HOST}/data`;
const GET_DATA = `${HOST}/php/data.php`;
// const GET_SEARCH = `${HOST}/search?keywords=`;
const GET_SEARCH = `${HOST}/php/search.php?keywords=`;

function App() {
    //

    const dispatch = useAppDispatch();

    const sections = useAppSelector((state) => state.sections.value);

    useEffect(() => {
        fetch(GET_DATA, {
            method: "GET",
            headers: { "Content-type": "application/json;charset=UTF-8" },
        })
            .then((res) => res.json())
            .then((data) => {
                dispatch(setSections(data));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="App">
            <BrowserRouter>
                <M2MHeader />
                <TitleBar />

                <Routes>
                    <Route
                        index
                        element={<SectionPage section={sections[0]} />}
                    />

                    <Route path="search" element={<SearchPage />} />

                    {sections.map((s, si) => {
                        return (
                            <Route path={s.slug} key={si}>
                                <Route
                                    index
                                    element={<SectionPage section={s} />}
                                />
                                {s.playlists.map((p, pi) => {
                                    return (
                                        <Route
                                            path={`${pi + 1}`}
                                            element={
                                                <VideosPage
                                                    section={s}
                                                    playlist={p}
                                                />
                                            }
                                            key={pi}
                                        />
                                    );
                                })}
                            </Route>
                        );
                    })}
                </Routes>
                <ModalVideoPlayer />
            </BrowserRouter>
        </div>
    );
}

function SectionPage({ section }: { section: Section }) {
    //

    const dispatch = useAppDispatch();

    dispatch(
        setTitleBarData({
            title: section.title,
            titleLink: "",
            subtitle: "",
            color: section.color,
            textColor: section.textColor,
        })
    );

    if (section.playlists.length === 1) {
        return <VideosPage section={section} playlist={section.playlists[0]} />;
    } else {
        return <PlaylistTable section={section} />;
    }
}

function VideosPage({
    section,
    playlist,
}: {
    section: Section;
    playlist: Playlist;
}) {
    //

    const dispatch = useAppDispatch();

    if (section.playlists.length > 1) {
        dispatch(
            setTitleBarData({
                title: section.title,
                titleLink: section.slug,
                subtitle: playlist.title,
                color: section.color,
                textColor: section.textColor,
            })
        );
    }

    return <VideosTable videos={playlist.videos} />;
}

function SearchPage() {
    //

    const dispatch = useAppDispatch();
    const keywords = useAppSelector((state) => state.searchKeywords.value);
    const searchTrigger = useAppSelector((state) => state.searchTrigger.value);
    const [results, setResults] = useState([defaultVideo.value]);

    useEffect(() => {
        //
        console.log(`searching... ${keywords}`);
        fetch(GET_SEARCH + keywords, {
            method: "GET",
            headers: { "Content-type": "application/json;charset=UTF-8" },
        })
            .then((res) => res.json())
            .then((data) => {
                console.log("data");
                console.log(data);
                setResults(data);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTrigger]);

    dispatch(
        setTitleBarData({
            title: "Búsqueda",
            titleLink: "",
            subtitle: `"${keywords}"`,
            color: "#f5f094",
            textColor: "#5144d1",
        })
    );

    return <VideosTable videos={results} />;
}

export default App;
