//

import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Section } from "../app/types";

function formatTitle(title: string) {
    return title.substring(title.search(/\./i) + 1).trim();
}

function PlaylistTable({ section }: { section: Section }) {
    //

    return (
        <div className="m2m-table mx-auto mt-4">
            <Table striped hover>
                <tbody>
                    {section.playlists.map((e, i) => {
                        return (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td>
                                    <Link
                                        className="m2m-link-2"
                                        to={`/${section.slug}/${i + 1}`}
                                    >
                                        {formatTitle(e.title)}
                                    </Link>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
}

export default PlaylistTable;
