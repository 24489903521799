//

import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import MenuSidebar from "./MenuSidebar";
import { useAppSelector } from "../app/hooks";
import { Link } from "react-router-dom";

function format(title: string) {
    return title.substring(title.search(/\d/i)).trim();
}

function TitleBar() {
    //

    const data = useAppSelector((state) => state.titleBarData.value);

    const [visible, setVisible] = useState(false);

    const capsuleStyle = {
        backgroundColor: data.color,
        color: data.textColor,
        borderColor: data.textColor,
    };

    return (
        <>
            <Row id="m2m-title-bar">
                <Col xs="10" id="m2m-title-bar-title" className="">
                    <Row>
                        <Col
                            sm="auto"
                            xs="auto"
                            className="title-capsule ms-4 mt-2 mb-1"
                            style={capsuleStyle}
                        >
                            {data.titleLink === "" ? (
                                <span>{data.title}</span>
                            ) : (
                                <Link
                                    className="m2m-link"
                                    to={`/${data.titleLink}`}
                                    style={{ color: data.textColor }}
                                >
                                    {data.title}
                                </Link>
                            )}
                        </Col>
                        {data.subtitle !== "" && (
                            <Col
                                sm="auto"
                                xs="auto"
                                className="title-capsule mx-4 mt-2 mb-1"
                                style={capsuleStyle}
                            >
                                {format(data.subtitle)}
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col xs="2" id="m2m-title-bar-button">
                    <Button
                        className="m2m-button"
                        type="button"
                        onClick={() => setVisible(true)}
                    >
                        <FontAwesomeIcon icon={faBars} />
                    </Button>
                </Col>
            </Row>
            <MenuSidebar visible={visible} hide={() => setVisible(false)} />
        </>
    );
}

export default TitleBar;
