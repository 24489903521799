//

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import sectionsReducer from "./slices/sections";
import videoReducer from "./slices/video";
import playerVisibilityReducer from "./slices/playerVisibility";
import titleBarDataReducer from "./slices/titleBarData";
import searchKeywordsReducer from "./slices/searchKeywords";
import searchTriggerReducer from "./slices/searchTrigger";

export const store = configureStore({
    reducer: {
        sections: sectionsReducer,
        video: videoReducer,
        playerVisibility: playerVisibilityReducer,
        titleBarData: titleBarDataReducer,
        searchKeywords: searchKeywordsReducer,
        searchTrigger: searchTriggerReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
