//

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Section } from "../types";

interface SectionsState {
    value: Section[];
}

export const initialState: SectionsState = {
    value: [
        {
            title: "Section",
            color: "#040c4a",
            textColor: "#f5f5f5",
            slug: "section",
            playlists: [
                {
                    title: "Playlist",
                    playlistId: "playlist-id",
                    videos: [
                        {
                            title: "Reducci\u00f3n de t\u00e9rminos semejantes | Concepto y ejercicios",
                            url: "https://youtu.be/APQN30fwB6A",
                            thumb: "https://i.ytimg.com/vi/APQN30fwB6A/maxresdefault.jpg",
                        },
                        {
                            title: "T\u00e9rmino algebraico | Concepto y ejercicios",
                            url: "https://youtu.be/5bA0JLEnFxY",
                            thumb: "https://i.ytimg.com/vi/5bA0JLEnFxY/maxresdefault.jpg",
                        },
                        {
                            title: "Expresiones algebraicas | Conceptos de coeficientes, constantes y cariables",
                            url: "https://youtu.be/PqYPspS9vYA",
                            thumb: "https://i.ytimg.com/vi/PqYPspS9vYA/maxresdefault.jpg",
                        },
                        {
                            title: "C\u00f3mo describes a M\u00e9xico |  Ft. Aldo Bartra #edutubers",
                            url: "https://youtu.be/LGyNjtuLgEU",
                            thumb: "https://i.ytimg.com/vi/LGyNjtuLgEU/maxresdefault.jpg",
                        },
                        {
                            title: "Diferencia entre cero y vac\u00edo | Kukis",
                            url: "https://youtu.be/gF0TiSjejcA",
                            thumb: "https://i.ytimg.com/vi/gF0TiSjejcA/maxresdefault.jpg",
                        },
                    ],
                },
            ],
        },
    ],
};

export const sectionsSlice = createSlice({
    name: "sections",
    initialState,
    reducers: {
        setSections: (state, action: PayloadAction<Section[]>) => {
            state.value = action.payload;
        },
    },
});

export const { setSections } = sectionsSlice.actions;

export default sectionsSlice.reducer;
