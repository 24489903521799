//

import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { hidePlayer } from "../app/slices/playerVisibility";

function ModalVideoPlayer() {
    //
    const dispatch = useAppDispatch();

    const playerVisibility = useAppSelector(
        (state) => state.playerVisibility.value
    );
    const video = useAppSelector((state) => state.video.value);

    return (
        <Modal
            show={playerVisibility}
            onHide={() => dispatch(hidePlayer())}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className="player-modal">
                <Modal.Title>{video.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="player-modal">
                <div id="embeded-video-container">
                    <iframe
                        title="YouTube video player"
                        width="953"
                        height="480"
                        src={`https://www.youtube.com/embed/${video.url.slice(
                            17
                        )}`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalVideoPlayer;
