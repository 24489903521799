//

import { createSlice } from "@reduxjs/toolkit";

interface SearchTriggerState {
    value: boolean;
}

export const initialState: SearchTriggerState = {
    value: false,
};

export const searchTriggerSlice = createSlice({
    name: "searchTrigger",
    initialState,
    reducers: {
        triggerSearch: (state) => {
            state.value = !state.value;
        },
    },
});

export const { triggerSearch } = searchTriggerSlice.actions;

export default searchTriggerSlice.reducer;
