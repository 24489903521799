//

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface TitleBarData {
    title: string;
    titleLink: string;
    subtitle: string;
    color: string;
    textColor: string;
}

interface TitleBarDataState {
    value: TitleBarData;
}

export const initialState: TitleBarDataState = {
    value: {
        title: "Section",
        titleLink: "",
        subtitle: "",
        color: "",
        textColor: "",
    },
};

export const titleBarDataSlice = createSlice({
    name: "titleBarData",
    initialState,
    reducers: {
        setTitleBarData: (state, action: PayloadAction<TitleBarData>) => {
            state.value = action.payload;
        },
    },
});

export const { setTitleBarData } = titleBarDataSlice.actions;

export default titleBarDataSlice.reducer;
