//

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface SearchKeywordsState {
    value: string;
}

export const initialState: SearchKeywordsState = {
    value: "",
};

export const searchKeywordsSlice = createSlice({
    name: "searchKeywords",
    initialState,
    reducers: {
        setSearchKeywords: (state, action: PayloadAction<string>) => {
            state.value = action.payload;
        },
    },
});

export const { setSearchKeywords } = searchKeywordsSlice.actions;

export default searchKeywordsSlice.reducer;
